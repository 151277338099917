import React from 'react';
import SEO from '../components/seo';
import { Layout as AntLayout, Row, Col } from 'antd';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';

const { Content } = AntLayout;

const Services = () => (
  <Layout className="services-layout">
    <SEO title="Services offered by IRC" />
    <Header theme="toupe">
      <h1>What we bring to the table</h1>
      <p>
        We offer a full suite of services that meet your compliance needs
        quickly and at the most competitive price.
      </p>
    </Header>
    <Content className="services-content">
      <Row gutter={32} type="flex">
        <Col xs={24} md={8}>
          <div>
            <div className="title-text">Product Development</div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <p>
            Insurance Regulatory Consultants, LLC (IRC) assists clients with
            product development to ensure compliance from the very beginning of
            any project. This allows for a smoother ride during the filing
            review phase, including fewer objections and faster review from
            state insurance departments. Development work includes drafting
            state amendatory endorsements and state exception pages for
            proprietary products, as well as creating any needed supporting
            documentation for propriety products and bureau adoptions.
            Development work is based on IRC's extensive filing experience,
            review of online legal resources and review of pertinent competitor
            filings. Our product development work is supported by our ability to
            anticipate compliance issues due to our filing history and
            experience with state examiners – we know the questions they’re
            going to ask, and we can help you answer them.
          </p>
        </Col>
      </Row>
      <Row gutter={32} type="flex">
        <Col xs={24} md={8}>
          <div>
            <div className="title-text">
              Filing Preparation &amp; Submission
            </div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <p>
            IRC handles all aspects of the insurance product filing process for
            its clients. This includes, but is not limited to, the completion of
            all state-required filing forms. IRC has the experience and the
            knowledge to complete all filings forms, including the more
            extensive California Prior Approval Rate Application, Rate Template
            and Company Exhibits, and the New York Review Standards Checklists
            and various Compliance Questionnaires. IRC also handles payment of
            any state required filing fees (as a pass through to clients when
            IRC's SERFF instance is used).{' '}
            <em>
              A standard forms/rates/rules project is typically submitted in all
              50 states and D.C. within 3 business days.
            </em>
          </p>
        </Col>
      </Row>
      <Row gutter={32} type="flex">
        <Col xs={24} md={8}>
          <div>
            <div className="title-text">
              Filing Follow-Up, Status Updates, &amp; Storage
            </div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <p>
            SERFF is always open and IRC is always checking it for updates from
            state insurance departments – approvals, objections letters and any
            other notes or messages that come our way. Objection letters are
            thoroughly reviewed and sent to clients with suggested responses,
            redlined revision proposals and any other information you may need
            to address the objections quickly and accurately. This may include
            copies of any referenced state statutes/regulations/bulletins and
            IRC insights into state questions. Status updates are sent on a
            regular basis to announce new approvals, summarize any required
            revisions, and list outstanding objections. Clients are provided
            online access to all submitted filings.{' '}
            <em>
              Please ask about IRC's upcoming software release, which will
              completely improve this stage of the filing process.
            </em>
          </p>
        </Col>
      </Row>
      <Row gutter={32} type="flex">
        <Col xs={24} md={8}>
          <div>
            <div className="title-text">Policy Rating Applications</div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <p>
            IRC develops customized user-friendly policy rating applications
            that are cost-effective, reliable, and compatible with many
            policy administration software solutions.
          </p>
          <ul>
            <li>Developed and maintained in MS Excel.</li>
            <li>
              Uses drop-down menus, check boxes, and buttons for most rating
              options.
            </li>
            <li>
              Customized to reflect underwriting guidelines for any line of
              insurance.
            </li>
            <li>
              Easy to maintain and update when rates change.
            </li>
            <li>
              All prior rating elements are retained and used (audits,
              endorsements, etc.) based on effective date.
            </li>
            <li>
              Consistently and reliably produce the correct premium for any
              effective date.
            </li>
            <li>
              Includes calculation of premium for cancellations and
              endorsements.
            </li>
            <li>
              Includes development of forms listing based on user input.
            </li>
            <li>
              Developed quickly, efficiently and cost effectively.
            </li>
            <li>
              All lines of insurance for both independent and bureau programs.
            </li>
          </ul>
        </Col>
      </Row>
      <Row gutter={32} type="flex">
        <Col xs={24} md={8}>
          <div>
            <div className="title-text">Rating Bureau Monitoring</div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <p>
            IRC provides regular summaries of bureau information to clients,
            capturing key components of all rating bureau circulars and
            bulletins. We advise clients on any required filing/regulatory
            action and proceed with filings as needed/directed by clients. We
            work with clients to provide this information in formats that work
            best for you. Our goal is to help you stay on top of this important
            maintenance task in the most efficient and effective way possible.
          </p>
        </Col>
      </Row>
      <Row gutter={32} type="flex">
        <Col xs={24} md={8}>
          <div>
            <div className="title-text">Statistical Reporting</div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <p>
            IRC brings its clients’ transactional statistics in to compliance
            with the various rating bureau requirements. IRC utilizes rating
            bureau validation utilities to efficiently report statistics. IRC
            also tracks all data calls and submissions.
          </p>
        </Col>
      </Row>
      <Row gutter={32} type="flex">
        <Col xs={24} md={8}>
          <div>
            <div className="title-text">Actuarial Services</div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <p>
            While IRC does not have an actuary on staff, we do have staff with
            significant experience and relevant background work in this area.
            IRC's clients routinely rely on this expertise to provide a review
            of industry data and provide Expense and Investment Income exhibits
            to develop and support company Loss Cost Multipliers. We also have
            extensive experience in preparing the California Rate Application,
            the California Rate Template and completing the Rate Collection
            System (RCS) rating examples within the Florida I-File system. We
            also have experience reviewing competitor filings and creating
            relevant rate support exhibits to gain approval in New York,
            Washington, Alaska and other tough-to-get-approved states. We often
            work in conjunction with our clients’ staff actuaries in the
            preparation of filing support exhibits and objection responses. We
            can make sure you’re providing the most relevant data for your
            filing and presenting it in the best way possible to facilitate
            approval.
          </p>
        </Col>
      </Row>
    </Content>
    <Footer />
  </Layout>
);

export default Services;
